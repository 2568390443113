<div class="page-title-area">
    <div class="heading">
        <h1>Privacy Policy</h1>
        <pre>Updated as of March 8, 2024</pre>
    </div>

    <p>This Privacy Policy aims to inform you about how Ubiquity Financial Inc., including its related organizations and affiliates, (hereinafter referred to as “Ubiquity Financial”, “we”, “us”, or “our”) collects, uses, and shares personal information gathered through this website. We prioritize your privacy and will not distribute your personal information to third parties without your consent, except as outlined in this Privacy Policy.</p>
    <p>By accessing and using this website, you consent to the terms of this Privacy Policy. If you disagree with any part of this policy, please refrain from using this website or providing us with your information.</p>
    <p>We are committed to informing you about the use of the personal information provided to us.</p>

    <h3>1. Transparency</h3>
    <p>We respect your privacy rights and are dedicated to transparency regarding the collection, use, and sharing of your personal information. This Privacy Policy covers our practices related to personal information collected (a) when visiting our websites, using our mobile applications, or accessing our product platform, (b) during communications or participation in our online or offline events, (c) from third parties such as resellers and content publishers, and (d) from any other source where information usage has been authorized. This policy does not extend to information collected by other websites not owned or operated by us. For any concerns or questions regarding this Privacy Policy or your personal information, please contact our <a href="mailto:compliance@ubiquity.financial">compliance team</a>.</p>

    <h3>2. The Information We Gather</h3>
    <p><strong>Voluntarily Provided Information:</strong> Includes contact and identity details, social security numbers, financial account information, social media details, transaction and communication data, and marketing preferences.</p>
    <p><strong>Information from Third Parties:</strong> We may receive personal information about you from other sources like marketing partners and public databases.</p>
    <p><strong>Automatically Provided Information:</strong> Includes device data, usage data, and information collected through cookies, web beacons, and JavaScript libraries.</p>

    <h3>3. Use of Information</h3>
    <p>We use or disclose your personal information for service delivery, site operation, research and development, marketing, compliance, and protection. Additionally, your personal information may be used or sold for commercial purposes like consumer credit reporting and fraud detection.</p>

    <h3>4. Sharing of Information</h3>
    <p>We share your personal information with service providers, payment processors, advertising partners, affiliates, and in compliance with legal requirements. We may also share your information during business transfers.</p>

    <h3>5. Third Party Sites and Services</h3>
    <p>We provide links to third-party websites and services, which are not covered by this Privacy Policy. We encourage you to review their privacy policies.</p>

    <h3>6. Children's Online Privacy Protection Act</h3>
    <p>We do not knowingly collect information from children under 16 and encourage parents to monitor their children's internet usage.</p>

    <h3>7. Public Forums</h3>
    <p>Information shared in public forums on our site becomes public. Please exercise caution when disclosing personal information.</p>

    <h3>8. International Information Transfers</h3>
    <p>Your information may be transferred to, and stored in, the United States or other locations outside your home country.</p>

    <h3>9. Security</h3>
    <p>We prioritize the security of your personal information and implement industry-standard measures to protect it.</p>

    <h3>10. Changes to This Privacy Policy</h3>
    <p>We may update this Privacy Policy and will notify you of any changes.</p>

    <h3>11. Making Changes to Your Personal Information</h3>
    <p>You can update or edit your personal information or discontinue your account by contacting our <a href="mailto:compliance@ubiquity.financial">compliance team</a>.</p>

    <h3>12. Contact Us</h3>
    <p>For questions about this Privacy Policy, contact our <a href="mailto:compliance@ubiquity.financial">compliance team</a>.</p>

    <h3>13. Important Information for California Residents</h3>
    <p>This section provides additional information about the rights of California residents under the CCPA.</p>

    <h3>14. Your California Privacy Rights</h3>
    <p>California residents can request access to and deletion of their personal information and opt

</div>

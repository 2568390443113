import { Component } from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-product-listings',
  templateUrl: './product-listings.component.html',
  styleUrl: './product-listings.component.scss'
})

export class ProductListingsComponent {
    sectionTitle: SectionTitleContent[] = [
        {
            title: 'Featured Products'
        }
    ];

    singleProjectsBox: SingleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: environment.imageStorageBucketUrl + '/product-logos/ubiquity-connect-dark.png',
            title: 'Connect - Report Data To The Credit Bureaus',
            subTitle: 'Seamlessly report data to the credit bureaus of your choice. You can either report to all 3 of the credit bureaus or only to the ones you need.',
            link: 'connect'
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }
}
class SectionTitleContent {
    title: string;
}
class SingleProjectsContent {
    icon: string;
    img: string;
    title: string;
    subTitle: string;
    link: string;
}

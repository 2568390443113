import { Component } from '@angular/core';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgForOf} from '@angular/common';
import {Router, RouterLink} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-know-you',
  standalone: true,
    imports: [
        LazyLoadImageModule,
        NgForOf,
        RouterLink
    ],
  templateUrl: './know-you.component.html',
  styleUrl: './know-you.component.scss'
})
export class KnowYouComponent {

    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    constructor(
        protected router: Router
    ) { }

    pageTitleArea: PageTitle[] = [
        {
            title: 'Products',
            subTitle: 'Know You',
        }
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: environment.imageStorageBucketUrl + '/product-logos/know-you-logo.png'
        }
    ];
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Know You AI - KYC Automation Made Simple',
            title1: 'About this Product',
            paragraphText1: 'Know You AI is a cutting-edge platform designed to empower businesses in building robust, global KYC/AML programs. This versatile tool stands at the forefront of adapting to ever-evolving regional regulations, allowing for the customization of user experiences based on active and passive risk signals. ',
            paragraphText2: 'It streamlines and scales operations by providing an all-in-one platform for user information collection, watchlist and sanctions screenings, and automated decision-making. Additionally, Know You AI\'s capability to reverify customers ensures consistent identity verification throughout their lifecycle, with the option to re-run screenings at specific intervals or during certain transactions. Its stringent data management protocols offer full control over redaction policies and audit trails, bolstering user protection against unauthorized access to personal information.',
            title2: 'Important Facts',
            title3: 'Application Areas',
            title4: 'Technologies That We Use',
            img1: environment.imageStorageBucketUrl + '/products/important-facts.jpg',
            img2: environment.imageStorageBucketUrl + '/services/charts.jpg'
        }
    ];
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Comprehensive Screening and Automation Features'
        },
        {
            title: 'Customizable and Compliant Onboarding Flows'
        },
        {
            title: 'Robust Data Security and Management'
        },
        {
            title: 'Continuous Customer Reverification'
        },
        {
            title: 'Streamlined Operations on a Single Platform'
        },
        {
            title: 'KYC/AML Compliance'
        }
    ];
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Retail Lenders',
            icon: 'flaticon-card',
        },
        {
            title: 'Healthcare',
            icon: 'flaticon-hospital',
        },
        {
            title: 'Auto Lenders',
            icon: 'flaticon-tracking',
        },
        {
            title: 'Banks',
            icon: 'flaticon-investment',
        },
        {
            title: 'Home Lenders',
            icon: 'flaticon-house',
        },
        {
            title: 'EDU Lenders',
            icon: 'flaticon-student',
        },
        {
            title: 'Dealerships',
            icon: 'flaticon-dealership',
        },
        {
            title: '& More',
            icon: 'flaticon-more',
        }
    ];
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'Angular'
        },
        {
            title: 'Node JS'
        },
        {
            title: 'Google Cloud Platform'
        },
        {
            title: 'Firestore'
        },
        {
            title: 'Big Query'
        }
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: 'Product Details',
            link: 'product-details'
        },
        {
            title: 'Application Areas',
            link: 'application-areas'
        },
        {
            title: 'Product Technologies',
            link: 'product-technologies'
        },
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'Connect Brochure Download',
            icon: 'bx bxs-file-pdf',
            link: `${environment.pdfStorageBucketUrl}/Connect By Ubiquity Brochure.pdf`
        },
        {
            title: 'API Documentation',
            icon: 'bx bxs-file-txt',
            link: environment.documentationUrl
        }
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+1 667-379-3446'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'Baltimore, MD. USA'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'sales@ubiquity.financial'
        }
    ];

    protected readonly environment = environment;

    scroll(el: string) {
        this.router.navigate(['/products/know-you'], { fragment: el });
    }
}
class PageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}

import { Component } from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-homefour-testimonials',
    templateUrl: './homefour-testimonials.component.html',
    styleUrls: ['./homefour-testimonials.component.scss']
})
export class HomefourTestimonialsComponent {
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    constructor() { }

    sectionTitle: SectionTitleContent[] = [
        {
            title: 'What Our Customers Are Saying?',
            paragraphText: 'Explore how we\'ve assisted both small and large businesses in setting up and efficiently reporting credit info to the bureaus. Our process is straightforward, proven and user-friendly.'
        }
    ];
    singleTestimonialsBox: TestimonialsBoxContent[] = [
        {
            clientImg: environment.imageStorageBucketUrl + '/team/joe.png',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
            clientName: 'Joseph Alvini',
            clientDesignation: 'Co-CEO'
        },
        {
            clientImg: environment.imageStorageBucketUrl + '/team/tomi.jpeg',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
            clientName: 'Tomi Okiji',
            clientDesignation: 'Co-CEO'
        },
        {
            clientImg: environment.imageStorageBucketUrl + '/team/ben.jpeg',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
            clientName: 'Benjamin Davis',
            clientDesignation: 'Director - Cloud Architecture'
        }, 
        {
            clientImg: environment.imageStorageBucketUrl + '/team/rafiq.jpeg',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
            clientName: 'Rafiq Flucas',
            clientDesignation: 'Director - Software Development'
        }
    ];
    testimonialsBtn: Btn[] = [
        {
            link: '/',
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ];

    protected readonly environment = environment;
}
class SectionTitleContent {
    title: string;
    paragraphText: string;
}
class TestimonialsBoxContent {
    clientImg: string;
    paragraphText: string;
    clientName: string;
    clientDesignation: string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}

<app-homefour-banner></app-homefour-banner>

<app-homefour-featured-services></app-homefour-featured-services>

<app-homefour-why-choose-us></app-homefour-why-choose-us>

<app-homefour-industries-serve></app-homefour-industries-serve>


<app-homefour-testimonials></app-homefour-testimonials>


<app-homefour-blog></app-homefour-blog>

<section class="testimonials-area bg-23173a ptb-100">
    <div class="">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>Meet the Team</h2>
        </div>

        <div class="testimonials-slides team-container">
            <div *ngFor="let TestimonialsBoxContent of singleTestimonialsBox; index as i" class="team-box" style="padding: 25px; " >
                <img [defaultImage]="loadingImg" [lazyLoad]="TestimonialsBoxContent.clientImg" class="shadow-sm" alt="image">
                <h3> {{TestimonialsBoxContent.clientName}} </h3>
                <h5> {{TestimonialsBoxContent.clientDesignation}}</h5>
            </div>
        </div>

        <div class="testimonials-view-btn text-center" *ngFor="let Btn of testimonialsBtn;">
        </div>
    </div>

    <div class="vector-shape9"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/vector-shape9.png" alt="image"></div>
    <div class="vector-shape10"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/vector-shape10.png" alt="image"></div>
</section>

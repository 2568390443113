import { Component } from '@angular/core';

@Component({
    selector: 'app-homefour-featured-services',
    templateUrl: './homefour-featured-services.component.html',
    styleUrls: ['./homefour-featured-services.component.scss']
})

export class HomefourFeaturedServicesComponent {

    constructor() { }


    sectionTitle: SectionTitleContent[] = [
        {
            title: 'What We Do',
            paragraphText: 'Ubiquity Financial provides one of the only end to end solutions for onboarding and managing your credit based customers. Let our seemless ecosystem help your businesses propel to the next level.'
        }
    ];

    singleFeaturedServicesBox: FeaturedServicesContent[] = [
        {
            image: '/assets/img/icon-1.png',
            title: 'Connect By Ubiquity',
            paragraphText: 'Connect is a one of a kind data furnishing/reporting solution that revolutionizes the way data is reported to the credit bureaus. It not only makes it super simple to report but also gives you a place to manage the data that\'s reported.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: '/products/connect'
        },
        {
            image: '/assets/img/icon-2.png',
            title: 'Know You AI',
            paragraphText: 'Leverage AI to onboard customers in a very fast and efficient way. This will not only help you onboard more customers but it will also cut mistakes made during the onboarding process down to a fraction of what they used to be',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: '/products/know-you'
        },
        {
            image: '/assets/img/icon-3.png',
            title: 'Risk Radar',
            paragraphText: 'Using predictive analytics Ubiquity can tell whether or not your customers are likely to default. This will help you to make better decisions on how to manage your customer base.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: '/products/risk-radar'
        }
    ];
}

class SectionTitleContent {
    title: string;
    paragraphText: string;
}

class FeaturedServicesContent {
    image: string;
    title: string;
    paragraphText: string;
    linkIcon: string;
    linkText: string;
    link: string;
}

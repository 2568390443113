<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape3.png" alt="image"></div>
</section>

<section class="contact-area pt-70">
    <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
        <h2>{{sectionTitleContent.title}}</h2>
        <p>{{sectionTitleContent.paragraphText}}</p>
    </div>
</section>

<div class="grid-container">
    <section class="contact-info-area contact-form center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group mb-3">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mb-3">
                                        <input type="email" name="email" class="form-control" id="email" placeholder="Email address">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mb-3">
                                        <input type="text" name="phone_number" class="form-control" id="phone_number" placeholder="Phone number">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mb-3">
                                        <input type="text" name="company_name" class="form-control" id="company_name" placeholder="Company name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mb-3">
                                        <select name="company_size" class="form-control" id="company_size">
                                            <option value="0">Number Of New Applicants Per Month (Estimate)</option>
                                            <option value="1">1 - 24 New Applicant(s) Per Month</option>
                                            <option value="2">25 - 49 New Applicants Per Month</option>
                                            <option value="3">50 - 249 New Applicants Per Month</option>
                                            <option value="4">250 - 999 New Applicants Per Month</option>
                                            <option value="5">1000+ New Applicants Per Month</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Get Your Spot In Line<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {
    year: number = new Date().getFullYear();
    constructor(
        private router: Router,
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location === '/home-two'
                    || this.location === '/home-four'
                    || this.location === '/services-details'
                    || this.location === '/events-details'
                    || this.location === '/courses'
                    || this.location === '/courses-details'){
                    this.footerClass = 'footer-area';
                } else if (this.location === '/about-us') {
                    this.footerClass = 'footer-area bg-fffaf3';
                } else {
                    this.footerClass = 'footer-area bg-color';
                }
            }
        });
    }
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';
    footerBgImage = '';
    location: string;
    footerClass: string;
    protected readonly environment = environment;

    ngOnInit(): void {
        this.footerBgImage = `background: url(${environment.imageStorageBucketUrl}/black-bg.jpg)`;
    }
}

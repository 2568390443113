import { Component } from '@angular/core';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgForOf} from '@angular/common';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-lab-listings',
  templateUrl: './lab-listings.component.html',
  styleUrl: './lab-listings.component.scss'
})
export class LabListingsComponent {
    sectionTitle: SectionTitleContent[] = [
        {
            title: 'Ubiquity Labs',
        }
    ];

    singleProjectsBox: SingleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: environment.imageStorageBucketUrl + '/product-logos/know-you-logo.png',
            title: 'Know You AI - End 2 End KYC Solution',
            subTitle: 'Automate decision-making and reduce manual checks with comprehensive KYC processes. Start for free today. Identity verification solutions suitable for all businesses. Confirm identities and conduct.',
            link: 'know-you'
        },
        {
            icon: 'bx bx-plus',
            img: environment.imageStorageBucketUrl + '/product-logos/risk-radar.png',
            title: 'Risk Radar - Automated Risk Assessment',
            subTitle: 'Discover a seamless, machine learning-driven process that evaluates customer risk at the time of application and continuously monitors their behavior, alerting you to any potential loan default risks over time.',
            link: 'risk-radar'
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }
}
class SectionTitleContent {
    title: string;
}
class SingleProjectsContent {
    icon: string;
    img: string;
    title: string;
    subTitle: string;
    link: string;
}

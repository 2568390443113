<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blogPostContent of singleBlogPost;">
                <div class="single-blog-post bg-fffbf5">


                    <div class="post-content">
                        <h3>
                            <a href="{{blogPostContent.link}}">
                            {{blogPostContent.title.rendered}}
                            </a>
                        </h3>
                        <div class="date">
                            <i class='flaticon-calendar'></i> {{blogPostContent.date | date: 'MMM d, y'}}
                        </div>

                        <ul class="post-meta d-flex justify-content-between align-items-center">
                            <li [innerHTML]="blogPostContent.content.rendered">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="vector-shape1" data-speed="0.06" data-revert="true"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/vector-shape1.png" alt="image"></div>
    <div class="vector-shape2" data-speed="0.06" data-revert="true"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/vector-shape2.png" alt="image"></div>
</section>

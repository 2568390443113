export const environment = {
    production: true,
    imageStorageBucketUrl: 'https://storage.googleapis.com/ubiquity-main-storage-prod/img',
    pdfStorageBucketUrl: 'https://storage.googleapis.com/ubiquity-main-storage-prod/pdfs',
    documentationUrl: 'https://docs.ubiquity.financial',
    blogUrl: 'https://blog.ubiquity.financial',
    startUrl: 'https://start.ubiquity.financial',
    guideURL: 'https://guides.ubiquity.financial',
    googleAnalyticsPropertyName: 'G-Z8YM692K2B',
    googleAdwordsPropertyName: 'AW-10937165436',
    apiUrl: 'https://api.ubiquity.financial/v1/',
};

import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-success',
  standalone: true,
  imports: [],
  templateUrl: './contact-success.component.html',
  styleUrl: './contact-success.component.scss'
})
export class ContactSuccessComponent {

}

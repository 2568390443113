import { Component } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Meta, Title} from '@angular/platform-browser';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ApiService} from "../../../services/api.service";
import {Router} from '@angular/router';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';
    email: string = "";
    name: string = "";
    phoneNumber: string = "";
    message: string = "";
    errors: any = {};
    submitted: boolean = false;
    loading: boolean = false;

    pageTitleArea: PageTitle[] = [
        {
            title: 'Contact Us'
        }
    ];
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Location',
            location: 'Baltimore, MD 21215, United States'
        }
    ];
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contact',
            number: '(+1) 667 - 379 - 3446',
            email: 'contact@ubiquity.financial'
        }
    ];
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Hours of Operation',
            text1: 'Monday - Friday: 09:00AM - 5:00PM EST',
            text2: 'Saturday: 10:30AM - 2:00PM EST'
        }
    ];

    sectionTitle: SectionTitleContent[] = [
        {
            subTitle: 'Get In Touch',
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ];
    contactImage: Image[] = [
        {
            img: environment.imageStorageBucketUrl + '/contact.png'
        }
    ];

    protected readonly environment = environment;

    dropdownList = [
        { item_id: 1, item_text: 'Connect' },
        { item_id: 2, item_text: 'Know You' },
        { item_id: 3, item_text: 'Risk Radar' },
    ];

    selectedItems = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: false
    };

    constructor(private title: Title, private meta: Meta, private api: ApiService<any>,protected router: Router        ) {
        this.title.setTitle('Contact Us - Reach Out for AI Financial Tools Support - Ubiquity Financial');
        this.meta.addTag({ name: 'description', content: 'Have questions about our AI-driven financial and credit tools? Reach out to the Ubiquity Financial team today! We\'re here to assist with your data furnishing, KYC, predictive analytics, and AI collections queries. Contact us for personalized support and expert insights into optimizing your financial operations.'});
    }

    submit(){
        this.loading = true;

        const contactInfo = {
            name: this.name,
            email: this.email,
            phone: this.phoneNumber,
            message: this.message,
            products: this.selectedItems
        };

        this.validateFields(contactInfo);

        if (this.validateFields(contactInfo)) {
            this.api.post(`website`, contactInfo).subscribe((res) => {
                if (res.code === 201) {
                    this.submitted = true;
                    this.router.navigate(['/contact'], {  });
                }

                this.loading = false;
            });
        }
        else{
            this.loading = false;
        }
    }

    validateFields(data){
        this.errors = {};
        const requiredFields = ["name", "email", "phone"];

        for(const field of requiredFields){
          const value = data[field] ;
          if(value === "" || value === null){
            this.errors[field] = {message: "This field is required"};
          }
        }

        return Object.entries(this.errors).length === 0;
      }

    onItemSelect(item: any){
        this.selectedItems.push(item.item_text);
        console.log(this.selectedItems);
    }
    OnItemDeSelect(item: any){
        let items = this.selectedItems;
        const index = this.selectedItems.indexOf(item.item_text);
        if(index !== -1){
            items.splice(index,1);
        }
        this.selectedItems = items;
    }
    onSelectAll(items: any){
        this.selectedItems = items.map((item) => item.item_text);
        console.log(this.selectedItems);
    }
    onDeSelectAll(items: any){
        this.selectedItems = [];
    }
}
class PageTitle {
    title: string;
}
class InfoBox1 {
    icon: string;
    title: string;
    location: string;
}
class InfoBox2 {
    icon: string;
    title: string;
    number: string;
    email: string;
}
class InfoBox3 {
    icon: string;
    title: string;
    text1: string;
    text2: string;
}

class SectionTitleContent {
    subTitle: string;
    title: string;
    paragraphText: string;
}
class Image {
    img: string;
}

<section class="projects-area pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12" *ngFor="let singleProjectsContent of singleProjectsBox;">
                <div class="single-projects-box box-card">
                    <div class="image">
                        <img [src]="singleProjectsContent.img" alt="image">

                    </div>

                    <div class="content">
                        <h3><a routerLink="{{singleProjectsContent.link}}">{{singleProjectsContent.title}}</a></h3>
                        <span>{{singleProjectsContent.subTitle}}</span>
                        <div class="content-read-more">
                            <a routerLink="{{singleProjectsContent.link}}">Read More +</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

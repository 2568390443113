import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageFourComponent } from './components/pages/home/homepage-four.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import {ConnectComponent} from './components/pages/connect/connect.component';
import {ErrorPageComponent} from './components/pages/error-page/error-page.component';
import {ProductsPageComponent} from './components/pages/products-page/products-page.component';
import {RiskRadarComponent} from './components/pages/risk-radar/risk-radar.component';
import {KnowYouComponent} from './components/pages/know-you/know-you.component';
import {TermsComponent} from './components/pages/terms/terms.component';
import {PrivacyComponent} from './components/pages/privacy/privacy.component';
import {LabSignUpComponent} from './components/pages/lab-sign-up/lab-sign-up.component';
import {ContactSuccessComponent} from './components/pages/contact-page/contact-success/contact-success.component';
import {LabSignUpSuccessComponent} from './components/pages/lab-sign-up/lab-sign-up-success/lab-sign-up-success.component';

const routes: Routes = [
    {path: '', component: HomepageFourComponent},
    {path: 'about-us', component: AboutPageComponent},
    {path: 'products/connect', component: ConnectComponent},
    {path: 'products/risk-radar', component: RiskRadarComponent},
    {path: 'products/risk-radar/sign-up', component: LabSignUpComponent},
    {path: 'products/risk-radar/sign-up/success', component: LabSignUpSuccessComponent},
    {path: 'products/know-you', component: KnowYouComponent},
    {path: 'products/know-you/sign-up', component: LabSignUpComponent},
    {path: 'products/know-you/sign-up/success', component: LabSignUpSuccessComponent},
    {path: 'products', component: ProductsPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {path: 'contact/success', component: ContactSuccessComponent},
    {path: 'terms', component: TermsComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'splash', loadChildren: () => import('./components/pages/splash/splash.module').then(m => m.SplashModule)},
    {path: '**', component: ErrorPageComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

<footer class="{{footerClass}}" [style]="footerBgImage">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <picture>
                            <source type="image/webp" srcset="{{environment.imageStorageBucketUrl}}/logo-light.webp">
                            <source type="image/png" srcset="{{environment.imageStorageBucketUrl}}/logo-light.png">
                            <img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/logo-light.png" alt="light color logo">
                        </picture>
                    </a>
                    <p style="font-size: 12px;">Revolutionizing Credit Management for Businesses. As a B2B financial software leader, we specialize in crafting cutting-edge tools tailored for the credit industry. Our solutions are designed to streamline operations, enhance accuracy, and boost efficiency, helping your business stay ahead in the dynamic world of finance</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li><a href="{{environment.blogUrl}}">Our Blog</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Products</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/products/connect">Connect</a></li>
                        <li><a routerLink="/products/know-you">Know You AI</a></li>
                        <li><a routerLink="/products/risk-radar">Risk Radar</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-phone-call'></i><a href="tel:+667-379-3446">+1 (667) 379-3446</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:hello@wilo.com">sales&#64;ubiquity.financial</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>©{{ year }} Ubiquity Financial Inc. All Rights Reserved</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy">Privacy Policy</a></li>
                        <li><a routerLink="/terms">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-map"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/footer-map.png" alt="image"></div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>

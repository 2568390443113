<div class="page-title-area">
    <div class="heading">
        <h1>Terms Of Service</h1>
        <pre>Updated as of March 8, 2024</pre>
    </div>

    <p>This Privacy Policy aims to clarify how Ubiquity Financial Inc. ("we", "us", or "our") collects, utilizes, and shares personal data through this website. We prioritize your privacy and pledge not to share your personal information with third parties without your consent, except as outlined in this document.</p>
    <p>By accessing and using this website, you consent to the terms outlined in this Privacy Policy. Should you disagree with any part of these terms, we request that you refrain from using this website and providing us with your personal information.</p>
    <p>We commit to transparently inform you about our practices regarding your personal data.</p>
    <h2>1. Transparency</h2>
    <p>We respect your right to privacy and are dedicated to being clear about how we collect, use, and share your personal information. This policy applies to data we gather when you visit our site, utilize our applications, or engage with our online or offline activities. It also covers information we receive from third parties and other authorized sources. This policy does not extend to data collected by sites not owned or operated by us. For inquiries about this policy, contact us at {{complianceEmail}}.</p>
    <h2>2. The Information We Collect</h2>
    <p>We collect various types of personal information, including:</p>
    <ul>
        <li>Contact and identity details like name, address, email, and phone number.</li>
        <li>Financial information such as bank account details.</li>
        <li>Social media information.</li>
        <li>Transaction details, including product selections and purchase history.</li>
    </ul>
    <p>We may also receive personal information about you from other sources and automatically collect certain data through cookies and other tracking technologies.</p>
    <h2>3. Use of Information</h2>
    <p>Your personal data is used to deliver and improve services, communicate with you, process transactions, and for marketing purposes, among other uses detailed within this policy.</p>
    <h2>4. Sharing of Information</h2>
    <p>Except as outlined in this policy, your personal information is not shared. It may be disclosed to service providers, affiliates, and under legal requirements, as further described herein.</p>
    <h2>5. Third Party Sites and Services</h2>
    <p>We may link to third-party sites or services, for which we are not responsible. We encourage you to review their privacy policies.</p>
    <h2>6. Children's Privacy</h2>
    <p>We do not knowingly collect personal information from children under 16.</p>
    <h2>7. International Information Transfers</h2>
    <p>Your information may be transferred and stored in the United States or other countries, subject to our strict privacy standards.</p>
    <h2>8. Security</h2>
    <p>We employ industry-standard security measures to protect your information, though no internet transmission is entirely secure.</p>
    <h2>9. Changes to This Privacy Policy</h2>
    <p>We may update this policy and will notify you of significant changes. Your continued use signifies acceptance of those changes.</p>
    <h2>10. Accessing and Updating Your Information</h2>
    <p>You can update your personal information at any time via your account settings.</p>
    <h2>11. Contact Us</h2>
    <p>For questions or concerns about our privacy practices, contact our Privacy Officer at <a href="mailto:compliance@ubiquity.financial">compliance</a> or by mail at:</p>
    <address>
        Ubiquity Financial Inc.<br>
        Attn: Legal Department<br>
        3206 Barrington Rd.<br>
        Baltimore, MD 21215<br>
        {{complianceEmail}}
    </address>
</div>

import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-homepage-four',
  templateUrl: './homepage-four.component.html',
  styleUrls: ['./homepage-four.component.scss']
})
export class HomepageFourComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {
      this.title.setTitle('AI-Enhanced Financial Solutions: Credit Tools, Data Furnishing & KYC Services - Ubiquity Financial');
      this.meta.addTag({ name: 'description', content: 'Discover cutting-edge AI solutions in financial services with Ubiquity Financial. Our platform offers advanced data furnishing, AI-powered Know Your Customer (KYC) tools, predictive analytics, and efficient AI collections systems. Streamline your financial operations and enhance decision-making with our innovative and user-friendly tools, designed to meet the evolving needs of modern finance professionals.'});
  }

  ngOnInit(): void {
      console.log('This is Homepage Four');
  }

}

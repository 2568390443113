import { Component } from '@angular/core';

@Component({
  selector: 'app-lab-sign-up-success',
  standalone: true,
  imports: [],
  templateUrl: './lab-sign-up-success.component.html',
  styleUrl: './lab-sign-up-success.component.scss'
})
export class LabSignUpSuccessComponent {

}

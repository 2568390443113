import { Component } from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-homefour-industries-serve',
    templateUrl: './homefour-industries-serve.component.html',
    styleUrls: ['./homefour-industries-serve.component.scss']
})
export class HomefourIndustriesServeComponent {
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    constructor() { }

    sectionTitle: SectionTitleContent[] = [
        {
            title: 'Industries We Serve',
            paragraphText: 'Streamline your credit reporting process with our comprehensive solution. Effortlessly identify customers, report data, and predict payment behaviors without the hassle of building and maintaining complex systems. Discover our innovative products and simplify your credit reporting journey'
        }
    ];
    singleIndustriesServeBox: IndustriesServeContent[] = [
        {
            title: 'Retail Lenders',
            icon: 'flaticon-card',
        },
        {
            title: 'Healthcare',
            icon: 'flaticon-hospital',
        },
        {
            title: 'Auto Lenders',
            icon: 'flaticon-tracking',
        },
        {
            title: 'Banks',
            icon: 'flaticon-investment',
        },
        {
            title: 'Home Lenders',
            icon: 'flaticon-house',
        },
        {
            title: 'EDU Lenders',
            icon: 'flaticon-student',
            link: 'services-details'
        },
        {
            title: 'Dealerships',
            icon: 'flaticon-dealership',
        },
        {
            title: '& More',
            icon: 'flaticon-more',
        }
    ];

    protected readonly environment = environment;
}
class SectionTitleContent {
    title: string;
    paragraphText: string;
}
class IndustriesServeContent {
    title: string;
    icon: string;
    link?: string;
}

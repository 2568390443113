import {Component, OnInit} from '@angular/core';
import {NgForOf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lab-sign-up',
  standalone: true,
    imports: [
        NgForOf,
        FormsModule,
        LazyLoadImageModule,
        NgMultiSelectDropDownModule,
        ReactiveFormsModule,
        RouterLink
    ],
  templateUrl: './lab-sign-up.component.html',
  styleUrl: './lab-sign-up.component.scss'
})
export class LabSignUpComponent implements OnInit {
    protected readonly environment = environment;
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    pageTitleArea: PageTitle[] = [
        {
            title: 'Join Our Lab'
        }
    ];

    sectionTitle: SectionTitleContent[] = [
        {
            title: '',
            paragraphText: 'Hurry, Spots are very limited and we will only be accepting applications for a limited time.'
        }
    ];

    constructor(
        private title: Title,
        private meta: Meta,
        private ar: ActivatedRoute
    ) {
        this.title.setTitle('Contact Us - Reach Out for AI Financial Tools Support - Ubiquity Financial');
        this.meta.addTag({
            name: 'description',
            content: 'Have questions about our AI-driven financial and credit tools? Reach out to the Ubiquity Financial team today! We\'re here to assist with your data furnishing, KYC, predictive analytics, and AI collections queries. Contact us for personalized support and expert insights into optimizing your financial operations.'
        });
    }

    ngOnInit(): void {
        this.ar.url.subscribe((us) => {
            const product = us[1].path;

            if (product === 'know-you') {
                this.sectionTitle[0].title = 'Ready To Try \'Know You AI\'? Join The Wait List!';
            } else if (product === 'risk-radar') {
                this.sectionTitle[0].title = 'Ready To Try \'Risk Radar\'? Join The Wait List!';
            }
        });
    }
}
class PageTitle {
    title: string;
}

class SectionTitleContent {
    title: string;
    paragraphText: string;
}

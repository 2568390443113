import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-homefour-banner',
    templateUrl: './homefour-banner.component.html',
    styleUrls: ['./homefour-banner.component.scss']
})
export class HomefourBannerComponent implements OnInit {

    constructor() { }
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';
    bgImageUrl = '';

    bannerWrapperContent: Content[] = [
        {
            title: 'Changing The Face Of Credit',
            paragraphText: 'Ubiquity Financial, a key player in the financial services industry, is committed to helping businesses manage their customers, in an ever growing market place',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'Products',
            defaultBtnLink: 'products',
            optionalBtnIcon: 'flaticon-tick',
            optionalBtnText: 'Connect With Us',
            optionalBtnLink: 'contact'
        }
    ];

    protected readonly environment = environment;

    ngOnInit(): void {
        this.bgImageUrl = `background: url(${environment.imageStorageBucketUrl}/main-banner/bg4.jpg); background-position: bottom right; background-size: cover; background-repeat: no-repeat`;
    }
}
class Content {
    title: string;
    paragraphText: string;
    defaultBtnIcon: string;
    defaultBtnText: string;
    defaultBtnLink: string;
    optionalBtnIcon: string;
    optionalBtnText: string;
    optionalBtnLink: string;
}

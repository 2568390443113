import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ApiService<T> {
    constructor(private http: HttpClient) {}

    get(url: string, params: object, auth: string = ''): Observable<any> {
        let options: object;

        if (auth) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth}`,
                'Access-Control-Allow-Origin': '*'
            });
            options = { headers,  params };
        } else {
            options = { params };
        }

        return this.http.get<any>(url, options )
            .pipe(
                catchError(this.handleError),
                map((res: any) => {
                    return res;
                }));
    }

    post(url: string, params: object, auth: string = ''): Observable<any> {
        let options: object;

        if (auth) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth}`,
                'Access-Control-Allow-Origin': '*'
            });
            options = { headers };
        }

        return this.http.post<any>(environment.apiUrl +  url, params, options)
            .pipe(
                catchError(this.handleError),
                map((res: any) => {
                    return res;
                }));
    }

    private handleError(error: HttpErrorResponse): Observable<any> {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => error.error);
    }
}

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img [defaultImage]="loadingImg" lazyLoad="{{environment.imageStorageBucketUrl}}/shape/shape3.png" alt="image"></div>
</section>

<app-product-listings><</app-product-listings>
<app-lab-listings></app-lab-listings>

import { Component } from '@angular/core';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgForOf} from '@angular/common';
import {Router, RouterLink} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-risk-radar',
  standalone: true,
    imports: [
        LazyLoadImageModule,
        NgForOf,
        RouterLink
    ],
  templateUrl: './risk-radar.component.html',
  styleUrl: './risk-radar.component.scss'
})
export class RiskRadarComponent {

    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    constructor(
        protected router: Router
    ) { }

    pageTitleArea: PageTitle[] = [
        {
            title: 'Products',
            subTitle: 'Risk Radar',
        }
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: environment.imageStorageBucketUrl + '/product-logos/risk-radar.png'
        }
    ];
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Connect By Ubiquity - Credit Reporting Made Simple',
            title1: 'About this Product',
            paragraphText1: 'Introducing our cutting-edge predictive analysis tool, designed to revolutionize risk management in the financial sector. Our innovative product leverages machine learning to evaluate new users at the point of sign-up, assigning a comprehensive risk score that accurately predicts the likelihood of loan default. This initial assessment empowers our partner companies with valuable insights, enabling them to make informed decisions and tailor their services to each individual\'s financial profile. Our system not only assesses risk at the outset but also continuously monitors each user\'s financial behavior. This dynamic approach ensures that any potential risk of default is identified at the earliest possible stage.',
            paragraphText2: 'Our product stands out by offering continuous monitoring and real-time alerts. If a user\'s financial behavior indicates an increased risk of defaulting, our system promptly notifies our partner companies. This proactive feature allows for immediate intervention, offering a critical window to mitigate risks and implement strategies to support the user in maintaining their financial commitments. By integrating our tool into your operations, your company can significantly reduce the incidence of loan defaults, enhance customer satisfaction, and strengthen your financial stability. Embrace the future of financial risk management with our state-of-the-art predictive analysis tool, where technology meets reliability to safeguard your business interests.',
            title2: 'Important Facts',
            title3: 'Application Areas',
            title4: 'Technologies That We Use',
            img1: environment.imageStorageBucketUrl + '/products/important-facts.jpg',
            img2: environment.imageStorageBucketUrl + '/services/charts.jpg'
        }
    ];
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Advanced Predictive Analysis Using AI'
        },
        {
            title: 'Continuous Risk Monitoring'
        },
        {
            title: 'Real-Time Alerts'
        },
        {
            title: 'Data-Driven Decision Making'
        },
        {
            title: 'Reduction in Loan Defaults'
        },
        {
            title: 'Enhanced Customer Support'
        }
    ];
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Retail Lenders',
            icon: 'flaticon-card',
        },
        {
            title: 'Auto Lenders',
            icon: 'flaticon-tracking',
        },
        {
            title: 'Banks',
            icon: 'flaticon-investment',
        },
        {
            title: 'Home Lenders',
            icon: 'flaticon-house',
        },
        {
            title: 'EDU Lenders',
            icon: 'flaticon-student',
        },
        {
            title: '& More',
            icon: 'flaticon-more',
        }
    ];
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'Angular'
        },
        {
            title: 'Node JS'
        },
        {
            title: 'Google Cloud Platform'
        },
        {
            title: 'Firestore'
        },
        {
            title: 'Big Query'
        }
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: 'Product Details',
            link: 'product-details'
        },
        {
            title: 'Application Areas',
            link: 'application-areas'
        },
        {
            title: 'Product Technologies',
            link: 'product-technologies'
        },
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'Connect Brochure Download',
            icon: 'bx bxs-file-pdf',
            link: `${environment.pdfStorageBucketUrl}/Connect By Ubiquity Brochure.pdf`
        },
        {
            title: 'API Documentation',
            icon: 'bx bxs-file-txt',
            link: environment.documentationUrl
        }
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+1 667-379-3446'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'Baltimore, MD. USA'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'sales@ubiquity.financial'
        }
    ];

    protected readonly environment = environment;

    scroll(el: string) {
        this.router.navigate(['/products/risk-radar'], { fragment: el });
    }
}
class PageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}

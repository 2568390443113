<div class="banner-wrapper" [style]="bgImageUrl">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="banner-wrapper-content" *ngFor="let Content of bannerWrapperContent;">
                    <h1 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">{{Content.title}}</h1>
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">{{Content.paragraphText}}</p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <a routerLink="/{{Content.optionalBtnLink}}" class="default-btn wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.optionalBtnIcon}}"></i>{{Content.optionalBtnText}}<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="banner-wrapper-animation-image">
                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img1.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img1.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img1.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img2.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img2.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img2.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img3.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img3.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img3.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img4.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img4.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img4.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img5.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img5.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img5.png'"
                            class="wow animate__animated animate__fadeInUp" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img6.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img6.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img6.png'"
                            class="wow animate__animated animate__fadeInUp" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img7.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img7.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img7.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img8.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img8.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img8.png'"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img9.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img9.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img9.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img10.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/img10.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/img10.png'"
                            class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                    <picture>
                        <source
                            type="image/webp"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/main-pic.webp">
                        <source
                            type="image/png"
                            srcset="{{environment.imageStorageBucketUrl}}/main-banner/banner-four/main-pic.png">
                        <img
                            [src]="environment.imageStorageBucketUrl + '/main-banner/banner-four/main-pic.png'"
                            class="wow animate__animated animate__fadeInUp" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image"
                        >
                    </picture>

                </div>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ApiService} from '../../../../services/api.service';

@Component({
    selector: 'app-homefour-blog',
    templateUrl: './homefour-blog.component.html',
    styleUrls: ['./homefour-blog.component.scss']
})
export class HomefourBlogComponent {
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';
    sectionTitle: SectionTitleContent[] = [
        {
            title: 'Latest Blog Posts',
            paragraphText: 'Check out our latest blog posts for valuable insight into the credit and finance industry',
        }
    ];

    singleBlogPost: BlogPostContent[] = [];

    protected readonly environment = environment;

    constructor(
        private api: ApiService<any>
    ) {}

    ngOnInit() {
        if (this.environment.production) {
            this.api.get(this.environment.blogUrl + '/wp-json/wp/v2/posts', {}).subscribe((res: BlogPostContent[]) => {

                let x = 0;
                for (const post of res) {
                    if (x < 3) {
                        post.content.rendered = post.content.rendered.split(' ').slice(0, 25).join(' ') + '...';
                        this.singleBlogPost.push(post);
                    }

                    x += 1;
                }
            });
        }
    }
}
class SectionTitleContent {
    title: string;
    paragraphText: string;
}
class BlogPostContent {
    link: string;
    content: BlogPostContentRendered;
    date: string;
    title: BlogPostContentRendered;
}

class BlogPostContentRendered {
    rendered: string;
}


<section class="featured-services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12" *ngFor="let featuredServicesContent of singleFeaturedServicesBox;">
                <div class="single-featured-services-box box-card" style="text-align: center;">
                    <div style="width: 200px; height: 210px; margin: auto auto; margin-bottom: 15px;">
                        <img src="{{featuredServicesContent.image}}" alt="">
                    </div>
                    <h3 style="display: block; min-height: 60px;"><a routerLink="/{{featuredServicesContent.link}}">{{featuredServicesContent.title}}</a></h3>
                    <p style="display: block; min-height: 220px;">{{featuredServicesContent.paragraphText}}</p>
                    <a routerLink="/{{featuredServicesContent.link}}" class="read-more-btn"><i class="{{featuredServicesContent.linkIcon}}"></i>{{featuredServicesContent.linkText}}</a>
                </div>
            </div>
        </div>
    </div>
</section>

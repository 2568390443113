import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {environment} from '../environments/environment';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
]
})

export class AppComponent implements OnInit {
    isBrowser = false;
    cssBackgroundImages: {className: string, beforeImage: string, image: string, afterImage: string}[] = [
        { className: 'page-title-area', beforeImage: 'transparent', image: 'page-title-bg.jpg', afterImage: 'right top no-repeat' }
    ];
    constructor(
        private gaService: GoogleAnalyticsService,
        @Inject(PLATFORM_ID) private platformId
    ) {
    }
    ngOnInit(): void {
        this.gaService.init();
    }

    changeOfRoutes() {
        this.isBrowser = isPlatformBrowser(this.platformId);

        if (this.isBrowser) {
            for (const bg of this.cssBackgroundImages) {
                const element = document.getElementsByClassName(bg.className)[0] as HTMLElement;
                if (element) {
                    element.style.background = bg.beforeImage
                        + ' url(' + environment.imageStorageBucketUrl + '/'
                        + bg.image + ') ' + bg.afterImage;
                }
            }
        }
    }
}

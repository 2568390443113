import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {isPlatformBrowser} from '@angular/common';

declare let gtag: any;
declare let document: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    isBrowser = false;
    constructor(private router: Router, @Inject(PLATFORM_ID) private platformId) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.isBrowser = isPlatformBrowser(this.platformId);

            if (this.isBrowser) {
                gtag('js', new Date());
                gtag('config', environment.googleAnalyticsPropertyName);
                gtag('config', environment.googleAdwordsPropertyName);
            }
        });
    }

    init() {
        if (this.isBrowser) {
            const script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-173474946-1';
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);

            const gtagEl = document.createElement('script');
            const gtagBody = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    `);
            gtagEl.appendChild(gtagBody);
            document.body.appendChild(gtagEl);
        }
    }
}

import { Component } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
    selector: 'app-connect',
    templateUrl: './connect.component.html',
    styleUrl: './connect.component.scss'
})

export class ConnectComponent {

    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    constructor(
        protected router: Router
    ) { }

    pageTitleArea: PageTitle[] = [
        {
            title: 'Products',
            subTitle: 'Connect',
        }
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: environment.imageStorageBucketUrl + '/product-logos/ubiquity-connect-dark.png'
        }
    ];
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Reporting Credit Data Made Simple',
            title1: 'About this Product',
            paragraphText1: 'Are you seeking an efficient method to report data to credit bureaus? Do you represent a large corporation in need of streamlining your reporting system through an bureau integrated CRM? If so, our product is the ideal solution for you. Connect not only simplifies data reporting but also serves as a versatile CRM, optimizing your customer management.',
            paragraphText2: 'By choosing Connect, you\'ll benefit from a seamless, automated process that enhances accuracy and saves time. Our system is designed to adapt to your unique business needs, providing a tailored experience that maximizes efficiency. With Connect, you gain more than just a tool; you acquire a partner in strategic data management and customer relations.',
            title2: 'Important Facts',
            title3: 'Application Areas',
            title4: 'Technologies That We Use',
            img1: environment.imageStorageBucketUrl + '/products/important-facts.jpg',
            img2: environment.imageStorageBucketUrl + '/services/charts.jpg'
        }
    ];
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Simple & Easy To Use API'
        },
        {
            title: 'Seamlessly Reports To All 3 Bureaus'
        },
        {
            title: 'Integrates Easily Into Any System'
        },
        {
            title: 'All Programming Languages Supported'
        },
        {
            title: 'State Of The Art UI'
        },
        {
            title: 'Built With Security In Mind'
        }
    ];

    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Retail Lenders',
            icon: 'flaticon-card',
        },
        {
            title: 'Healthcare',
            icon: 'flaticon-hospital',
        },
        {
            title: 'Auto Lenders',
            icon: 'flaticon-tracking',
        },
        {
            title: 'Banks',
            icon: 'flaticon-investment',
        },
        {
            title: 'Home Lenders',
            icon: 'flaticon-house',
        },
        {
            title: 'EDU Lenders',
            icon: 'flaticon-student',
        },
        {
            title: 'Dealerships',
            icon: 'flaticon-dealership',
        },
        {
            title: '& More',
            icon: 'flaticon-more',
        }
    ];

    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'Angular'
        },
        {
            title: 'Node JS'
        },
        {
            title: 'Google Cloud Platform'
        },
        {
            title: 'Firestore'
        },
        {
            title: 'Big Query'
        }
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: 'Product Details',
            link: 'product-details'
        },
        {
            title: 'Application Areas',
            link: 'application-areas'
        },
        {
            title: 'Product Technologies',
            link: 'product-technologies'
        },
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'Connect Brochure Download',
            icon: 'bx bxs-file-pdf',
            link: `${environment.pdfStorageBucketUrl}/Connect By Ubiquity Brochure.pdf`
        },
        {
            title: 'API Documentation',
            icon: 'bx bxs-file-doc',
            link: environment.documentationUrl
        },
        {
            title: 'Helpful Guides',
            icon: 'bx bxs-file-html',
            link: environment.guideURL
        }
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+1 667-379-3446'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'Baltimore, MD. USA'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'sales@ubiquity.financial'
        }
    ];

    protected readonly environment = environment;

    scroll(el: string) {
        this.router.navigate(['/products/connect'], { fragment: el });
    }
}
class PageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}

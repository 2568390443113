import { Component } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrl: './products-page.component.scss'
})

export class ProductsPageComponent {
    protected readonly environment = environment;

    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';
    pageTitleArea: PageTitle[] = [
        {
            title: 'Products'
        }
    ];

    constructor() { }
}

class PageTitle {
    title: string;
}


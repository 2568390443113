import { Component } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent {
    loadingImg: string = environment.imageStorageBucketUrl + '/image-loader.svg';

    constructor() { }

    pageTitleArea: PageTitle[] = [
        {
            title: 'About Us'
        }
    ];

    protected readonly environment = environment;
}
class PageTitle {
    title: string;
}
